<template>
  <div>
    <div class="min-vh-100">
      <b-row class="w-100 no-gutters">
        <b-col class="text-lg-left">
          <h1 class="mr-sm-4">DASHBOARD</h1>
        </b-col>
      </b-row>

      <div class="bg-white-border px-4 px-sm-5 py-4 mt-4">
        <Chart />
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "./components/Chart.vue";
export default {
    components: {
        Chart
    }
};
</script>

<style></style>