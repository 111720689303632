<template>
   <div>
      <div class="header-container">
         <div class="mr-2">
            <b-button-group class="buttongroup-custom w-100">
               <b-button
                  :class="[
                     tabIndex === 1 ? 'button-active' : 'button-noactive',
                  ]"
                  @click="onClickTab(1)"
                  >Day</b-button
               >
               <b-button
                  :class="[
                     tabIndex === 2 ? 'button-active' : 'button-noactive',
                  ]"
                  @click="onClickTab(2)"
                  >Week</b-button
               >
               <b-button
                  :class="[
                     tabIndex === 3 ? 'button-active' : 'button-noactive',
                  ]"
                  @click="onClickTab(3)"
                  >Month</b-button
               >
            </b-button-group>
         </div>
         <div
            class="
                d-flex
                align-items-center
                justify-content-between
                mb-2
                my-md-0
              "
         >
            <span class="f-size-12 header-txt-date">DATE: FORM </span>
            <div class="date-container">
               <datetime
                  class="date-picker"
                  value-zone="Asia/Bangkok"
                  placeholder="dd-mm-yyyy"
                  :maxDatetime="currentDate"
                  v-model="filter.startDate"
                  @close="onSelectDate"
               ></datetime>
            </div>
            <span class="f-size-12 header-txt-date"> TO </span>
            <div class="date-container mr-0">
               <datetime
                  class="date-picker"
                  value-zone="Asia/Bangkok"
                  placeholder="dd-mm-yyyy"
                  :maxDatetime="currentDate"
                  v-model="filter.endDate"
                  @close="onSelectDate"
               ></datetime>
            </div>
         </div>
      </div>
      <div class="my-4">
         <b-row class="no-gutters">
            <b-col cols="12" sm="6">
               <div class="mr-0 mb-4 mb-sm-0 mr-sm-2 stat-container">
                  <p class="head-widget">All User</p>
                  <span class="num-widget">{{
                     widgetData == 0
                        ? parseInt(widgetData.allUserFormat) + 1 || 0
                        : widgetData.allUserFormat || 0
                  }}</span>
               </div>
            </b-col>
            <b-col cols="12" sm="6">
               <div class="m-0 mb-4 mb-sm-0 mx-sm-2 stat-container">
                  <p class="head-widget">New Register</p>
                  <span class="num-widget">{{
                     widgetData.newUserFormat || 0
                  }}</span>
               </div>
            </b-col>
         </b-row>
      </div>

      <b-row class="no-gutters my-3">
         <b-col cols="12" xl="12" class="mb-3 mb-xl-2 row-container">
            <div class="bg-white p-3 h-100">
               <div class="mb-2">
                  <b-row>
                     <b-col cols="6" class="d-flex align-items-center">
                        <div>
                           <strong class="head-chart">Contract type</strong>
                           <br /><span>base on new register</span>
                        </div>
                     </b-col>
                     <b-col
                        cols="6"
                        class="d-flex align-items-center justify-content-end"
                     >
                        <!-- <b-button class="button-export" @click="exportChannelIntent"
                  >Export to Excel</b-button
                > -->
                        <span class="mr-1">Filter: </span>

                        <div class="dropdown" @click="showItemDropdown">
                           <div class="overselect"></div>

                           <b-form-select v-model="selectedFilter">
                              <b-form-select-option :value="null" disabled>
                                 {{ textSelect }}
                              </b-form-select-option>
                           </b-form-select>
                        </div>
                        <div class="multiselect" v-if="showDropdown">
                           <ul>
                              <li
                                 v-for="(option, index) in options"
                                 :key="index"
                              >
                                 <input
                                    type="checkbox"
                                    ref="dropdownFilter"
                                    :id="index"
                                    :value="option.value"
                                    v-model="checkSelected"
                                    @click="checkContractType($event)"
                                 />
                                 <span class="ml-1" :for="index">{{
                                    option.text
                                 }}</span>
                              </li>
                           </ul>
                        </div>
                     </b-col>
                  </b-row>
               </div>
               <div v-if="isContractLoading">
                  <div class="text-center">
                     <img
                        src="@/assets/images/icons/loading.svg"
                        alt="loading"
                        class="mb-3 img"
                        width="50px"
                        height="50px"
                     />
                  </div>
               </div>
               <div v-else class="my-3">
                  <LineChartContractIntent
                     :data="dataContractIntent"
                     :yAxisTitle="yAxisTitle"
                     :maxRegister="maxRegister"
                  />
               </div>
            </div>
         </b-col>

         <!-- Piechart ก้อนที่1 -->
         <b-col cols="12" md="6" class="mb-3 mb-xl-0">
            <div class="mr-xl-2 bg-white p-3 h-100 row-container">
               <div class="mb-2">
                  <b-row>
                     <b-col cols="6" class="d-flex align-items-center">
                        <div>
                           <strong class="head-chart">Access</strong>
                           <br /><span>base on ลูกค้า SKL Connect</span>
                        </div>
                     </b-col>
                  </b-row>
               </div>
               <div v-if="isAccessLoading">
                  <div class="text-center">
                     <img
                        src="@/assets/images/icons/loading.svg"
                        alt="loading"
                        class="mb-3 img"
                        width="50px"
                        height="50px"
                     />
                  </div>
               </div>
               <div v-else class="my-3">
                  <PieChartAccessIntent :data="dataAccessIntent" id="p1" />
               </div>
            </div>
         </b-col>

         <!-- Piechart ก้อนที่2 -->
         <b-col cols="12" md="6" class="mb-3 mb-xl-0">
            <div class=" bg-white p-3 h-100 row-container">
               <div class="mb-2">
                  <b-row>
                     <b-col cols="6" class="d-flex align-items-center">
                        <div>
                           <strong class="head-chart">Access</strong>
                           <br /><span>base on ลูกค้าทั่วไป</span>
                        </div>
                     </b-col>
                  </b-row>
               </div>
               <div v-if="isAccessLoading2">
                  <div class="text-center">
                     <img
                        src="@/assets/images/icons/loading.svg"
                        alt="loading"
                        class="mb-3 img"
                        width="50px"
                        height="50px"
                     />
                  </div>
               </div>
               <div v-else class="my-3">
                  <PieChartAccessIntent :data="AccessGuest" id="p2" />
               </div>
            </div>
         </b-col>
         <!-- </div> -->
      </b-row>
   </div>
</template>

<script>
import axios from "axios";
import LineChartContractIntent from "./LineChartContractIntent.vue";
import PieChartAccessIntent from "./PieChartAccessIntent.vue";
import moment from "moment";

export default {
   name: "Chart",
   components: {
      LineChartContractIntent,
      PieChartAccessIntent,
   },
   data() {
      return {
         tabIndex: 2,
         suggestMax: {},
         filter: {
            startDate: "",
            endDate: "",
            ViewAs: 2,
         },
         yAxisTitle: "สัปดาห์",
         widgetData: {
            newUserFormat: 0,
            allUserFormat: 0,
         },
         AccessGuest: [],
         selectedFilter: null,
         checkSelected: ["All", "HP", "FL", "TL", "PL"],
         options: [
            { text: "All", value: "All" },
            { text: "HP", value: "HP" },
            { text: "FL", value: "FL" },
            { text: "TL", value: "TL" },
            { text: "PL", value: "PL" },
         ],
         isContractLoading: true,
         isAccessLoading: true,
         isAccessLoading2: true,
         dataContractIntent: {},
         dataAccessIntent: [],
         showDropdown: false,

         //validate วันที่
         currentDate: new Date().toISOString().slice(0, 10),
         // text select
         textSelect: "All",
         maxRegister: 0,
      };
   },
   mounted: async function() {
      this.filter.startDate = moment()
         .subtract(1, "weeks")
         .format();

      let endDate = moment();
      endDate.set({ hour: 23, minute: 59, second: 59, millisecond: 59 });
      this.filter.endDate = endDate.format();

      await this.getAllData();
   },
   methods: {
      async getAllData() {
         await this.getWidget();
         await this.getContractIntent();
         await this.getAccessIntent();
         await this.getAccessGuest();
      },
      getWidget: async function() {
         const url = `${this.$baseUrl}/api/dashboard/Widget`;
         await axios({
            url: url,
            method: "post",
            headers: this.$headers,
            data: this.filter,
         }).then((response) => {
            if (response.data.result === 1) {
               this.widgetData = response.data.detail;
            }
         });
      },
      onSelectDate() {
         if (this.filter.startDate && this.filter.endDate) {
            // set เวลาเป็น 23.59.59
            let endDate = moment(this.filter.endDate);
            endDate.set({ hour: 23, minute: 59, second: 59, millisecond: 59 });
            this.filter.endDate = endDate.format();
            this.getAllData();
         }
      },
      onClickTab: async function(idx) {
         this.tabIndex = idx;
         this.filter.ViewAs = idx;
         switch (idx) {
            case 1:
               this.yAxisTitle = "วัน";
               break;
            case 2:
               this.yAxisTitle = "สัปดาห์";
               break;
            case 3:
               this.yAxisTitle = "เดือน";
               break;
            default:
               this.yAxisTitle = "สัปดาห์";
         }
         await this.getAllData();
      },
      getAccessIntent: async function() {
         this.isAccessLoading = true;
         const url = `${this.$baseUrl}/api/dashboard/Access`;
         await axios({
            url: url,
            method: "post",
            headers: this.$headers,
            data: this.filter,
         })
            .then((response) => {
               if (response.data.result === 1) {
                  const dataLists =
                     response.data.detail.length > 0
                        ? response.data.detail
                        : [];

                  this.dataAccessIntent = dataLists;

                  // [
                  //   {
                  //     label: "Report Installment Information",
                  //     count: 4.33,
                  //     countFormat: "4.33%",
                  //   },
                  //   {
                  //     label: "Report Barcode / QR Code",
                  //     count: 5.67,
                  //     countFormat: "5.67%",
                  //   },
                  //   {
                  //     label: "Report Online Payment",
                  //     count: 1.67,
                  //     countFormat: "1.67%",
                  //   },
                  //   {
                  //     label: "Report Check App",
                  //     count: 9.0,
                  //     countFormat: "9.00%",
                  //   },
                  //   {
                  //     label: "Report Point",
                  //     count: 48.67,
                  //     countFormat: "48.67%",
                  //   },
                  //   {
                  //     label: "Report Suggest",
                  //     count: 30.67,
                  //     countFormat: "30.67%",
                  //   },
                  // ];

                  this.isAccessLoading = false;
               }
            })
            .catch((error) => {
               this.dataAccessIntent = {};

               this.isAccessLoading = false;
            });
      },
      getAccessGuest: async function() {
         this.isAccessLoading2 = true;
         const url = `${this.$baseUrl}/api/dashboard/AccessGuest`;
         await axios({
            url: url,
            method: "post",
            headers: this.$headers,
            data: this.filter,
         }).then((response) => {
            console.log("resdata", response);
            if (response.data.result === 1) {
               this.AccessGuest = response.data.detail;
            }
         });
         this.isAccessLoading2 = false;
      },
      getContractIntent: async function() {
         let contractType = [...this.checkSelected];
         let index = this.checkSelected.indexOf("All");
         if (index !== -1) {
            contractType.splice(index, 1);
         }

         this.isContractLoading = true;
         const url = `${this.$baseUrl}/api/dashboard/Register`;
         await axios({
            url: url,
            method: "post",
            headers: this.$headers,
            data: {
               ...this.filter,
               ContractType: contractType,
            },
         })
            .then((response) => {
               if (response.data.result === 1) {
                  const dataLists =
                     response.data.detail.length > 0
                        ? response.data.detail
                        : [];
                  const hpCount = dataLists.map((e) => {
                     return {
                        name: e.label,
                        count: e.hpCount,
                        countFormat: e.hpCountFormat,
                     };
                  });
                  const flCount = dataLists.map((e) => {
                     return {
                        name: e.label,
                        count: e.flCount,
                        countFormat: e.flCountFormat,
                     };
                  });
                  const tlCount = dataLists.map((e) => {
                     return {
                        name: e.label,
                        count: e.tlCount,
                        countFormat: e.tlCountFormat,
                     };
                  });
                  const plCount = dataLists.map((e) => {
                     return {
                        name: e.label,
                        count: e.plCount,
                        countFormat: e.plCountFormat,
                     };
                  });

                  const dataResult = {
                     hpCount,
                     flCount,
                     tlCount,
                     plCount,
                  };
                  this.dataContractIntent = dataResult;
                  // console.log('dataContractIntent',this.dataContractIntent)
                  let keys = Object.keys(dataResult).map((el) => el);
                  let value = [];

                  for (let key of keys) {
                     let values = dataResult[key].map((o) => o.count);
                     value = [...values, ...value];
                  }
                  let max = Math.max(...value);
                  this.maxRegister = max;
                  this.isContractLoading = false;
                  this.getTextFilter();
               }
            })
            .catch((error) => {
               this.dataContractIntent = {};

               this.isContractLoading = false;
            });
      },
      getTextFilter() {
         let getAllFilter = this.$refs.dropdownFilter;

         let arr = [];

         getAllFilter.forEach((x) => {
            if (x.checked) {
               arr.push(x.value);
            }
         });

         let tranfromToText = arr.join(",");

         if (tranfromToText.includes("All")) {
            tranfromToText = "All";
         }
         this.textSelect = tranfromToText;
      },
      showItemDropdown() {
         this.showDropdown = !this.showDropdown;
      },
      checkContractType(e) {
         if (e.target.value == "All") {
            if (this.checkSelected.includes("All")) {
               this.checkSelected = [];
            } else {
               this.checkSelected = ["All", "HP", "FL", "TL", "PL"];
            }
         } else if (this.checkSelected.includes("All")) {
            // ติ๊กครบทุกช่อง แล้วช่องนึงออก
            let index = this.checkSelected.indexOf("All");
            if (index !== -1) {
               this.checkSelected.splice(index, 1);
            }
         } else if (
            this.checkSelected.length == 3 &&
            !this.checkSelected.includes(e.target.value)
         ) {
            // ติ๊กช่องอื่นจนครบ
            this.checkSelected.push("All");
         }

         if (this.checkSelected.includes(e.target.value)) {
            this.checkSelected.splice(
               this.checkSelected.indexOf(e.target.value),
               1
            );
         } else {
            this.checkSelected.push(e.target.value);
         }

         this.getContractIntent();
      },
   },
};
</script>

<style scoped>
span,
p {
   color: #3c4b64;
   font-size: 14px;
}
.header-txt-date {
   color: #3c4b64;
   font-size: 12px;
   /* padding-bottom: 10px; */
}
.header-container {
   display: flex;
   justify-content: space-between;
   flex-direction: row;
}
::v-deep .date-picker .vdatetime-input {
   margin-bottom: 0px;
}
.date-container {
   max-width: 35%;
   margin: 0 0.5rem;
}
.stat-container {
   padding: 1rem;
   border-radius: 4px;
   background: #dcdcdc;
   color: #3c4b64;
}
.head-widget {
   font-size: 14px;
}
.num-widget {
   color: #f47306;
   font-size: 16px;
}
.row-container {
   border: 1px solid #dedede;
}
.head-chart {
   color: #3c4b64;
   font-size: 16px;
}
/* dropdown */
.dropdown {
   cursor: pointer;
   width: 100px;
}

.multiselect {
   position: relative;
}
.multiselect ul {
   border: 1px solid #ddd;
   border-radius: 0 0 3px 3px;
   border-top: 0;
   right: 0;
   padding: 8px 8px;
   position: absolute;
   top: 1.1rem;
   width: 100px;
   max-height: 150px;
   overflow: auto;
   z-index: 1;
   background: white;
}
/* .pie-chart{
  max-width: 685px;
  width: 100%;
  display: flex;
} */

.overselect {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
}
@media (max-width: 720px) {
   .header-container {
      flex-direction: column-reverse;
   }
}
</style>
