<template>
   <canvas :id="id"></canvas>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
   name: "PieChartAccessIntent",
   props: {
      data: {
         required: true,
         type: Array,
      },
      id: {
         required: true,
      },
   },
   mounted() {
      console.log("data", this.data);
      var label = this.data.map(
         (item) => item.label + " " + item.totalFormat + " "+ "(" + item.countFormat + ")"
      );
      var count = this.data.map((item) => item.count);
      var totalFormat = this.data.map((item) => item.totalFormat);
      var countFormat = this.data.map((item) => item.countFormat);

      const ctx = document.getElementById(this.id).getContext("2d");
      new Chart(ctx, {
         type: "pie",
         plugins: [ChartDataLabels],
         data: {
            labels: label,
            datasets: [
               {
                  data: count,
                  backgroundColor: [
                     "rgb(128,193,65)",
                     "rgb(64,96,32)",
                     "rgb(244,115,6)",
                     "rgb(192,99,6)",
                     "rgb(188,188,188)",
                     "rgb(87, 88, 88)",
                  ],
               },
            ],
         },
         options: {
            layout: {
               padding: {
                  left: 20,
                  right: 20,
                  top: 20,
                  bottom: 20,
               },
            },
            responsive: true,
            plugins: {
               legend: {
                  position: "bottom",
                  align: "center",
               },
               datalabels: {
                  display: false,
                  color: "#3C4B64",
                  formatter: function(value, context) {
                     return (
                        totalFormat[context.dataIndex] +
                        " " +
                        "(" +
                        countFormat[context.dataIndex] +
                        ")"
                     );
                  },
                  anchor: "end",
                  align: "end",
               },
            },
            aspectRatio: 1.5,
         },
      });
   },
};
</script>

<style></style>
