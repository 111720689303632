<template>
   <canvas id="line-chart-contract-intent" height="400"></canvas>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
export default {
   name: "LineChartChannelIntent",
   props: {
      data: {
         type: Object,
         require: true,
      },
      yAxisTitle: {
         type: String,
         require: true,
      },
      maxRegister:{
        type: Number,
        required: false,
      }
   },
   data() {
      return {
         options: {
            responsive: true,
            maintainAspectRatio: false,
            parsing: {
               xAxisKey: "name",
               yAxisKey: "count",
            },
            layout: {
               padding: 0,
            },
            scales: {
               y: {
                  ticks: {
                     stepSize: 1,
                     beginAtZero: true,
                  },
                  suggestedMax: this.maxRegister +1,               
                  title: {
                     display: true,
                     align: "center",
                     text: "จำนวนสัญญา",
                     color: "#242424",
                     font: {
                        family: "Kanit-Regular",
                        size: 12,
                        lineHeight: 2,
                     },
                     padding: { top: 10, left: 0, right: 0, bottom: 10 },
                  },
               },
               x: {
                  ticks: {
                     beginAtZero: true,
                  },
                  title: {
                     display: true,
                     align: "center",
                     text: this.yAxisTitle,
                     color: "#242424",
                     font: {
                        family: "Kanit-Regular",
                        size: 12,
                        lineHeight: 2,
                     },
                     padding: { top: 0, left: 0, right: 0, bottom: 10 },
                  },
               },
            },
            plugins: {
               legend: {
                  display: true,
                  position: "bottom",
               },
               datalabels: {
                  color: "#3C4B64",
                  formatter: function(value, context) {
                     return value.countFormat;
                  },
                  anchor: "end",
                  align: "end",
               },
               tooltip: {
                  bodyAlign: "center",
                  yAlign: "bottom",
                  titleColor: "#575757",
                  backgroundColor: "#F1F1F1",
                  titleMarginBottom: 0,
                  padding: 12,
                  callbacks: {
                     title: function(context) {
                        const contextElement = context[0];
                        const chartDatas = contextElement.chart.data.datasets;
                        const labelCollect = chartDatas.map((chartData) => {
                           const label = chartData.label;
                           const index = contextElement.dataIndex;
                           const data = chartData.data[index];
                           const dataFormated = data.countFormat;
                           const unit = "คน";
                           return `${label} ${dataFormated} ${unit}`;
                        });
                        return labelCollect.join("\n");
                     },
                     label: function(context) {
                        return;
                     },
                     afterLabel: function(context) {
                        return;
                     },
                  },
               },
            },
         },
         gradient: null,
      };
   },
   mounted() {
      const ctx = document
         .getElementById("line-chart-contract-intent")
         .getContext("2d");
      const gradientColor1 = ctx.createLinearGradient(0, 0, 0, 350);
      gradientColor1.addColorStop(0, "rgba(128,193,65, 0.5)");
      gradientColor1.addColorStop(0.5, "rgb(128,193,65, 0.25)");
      gradientColor1.addColorStop(1, "rgba(128, 193, 65, 0)");

      const gradientColor2 = ctx.createLinearGradient(0, 0, 0, 350);
      gradientColor2.addColorStop(0, "rgba(188,188,188, 0.5)");
      gradientColor2.addColorStop(0.5, "rgba(188,188,188, 0.25)");
      gradientColor2.addColorStop(1, "rgba(188,188,188, 0)");

      const gradientColor3 = ctx.createLinearGradient(0, 0, 0, 350);
      gradientColor3.addColorStop(0, "rgba(244,115,6, 0.5)");
      gradientColor3.addColorStop(0.5, "rgba(244,115,6, 0.25)");
      gradientColor3.addColorStop(1, "rgba(244, 115, 6, 0)");

      const gradientColor4 = ctx.createLinearGradient(0, 0, 0, 350);
      gradientColor4.addColorStop(0, "rgba(64,96,32, 0.5)");
      gradientColor4.addColorStop(0.5, "rgba(64,96,32, 0.25)");
      gradientColor4.addColorStop(1, "rgba(64,96,32, 0)");

      new Chart(ctx, {
         type: "bar",
         plugins: [ChartDataLabels],
         data: {
            datasets: [
               {
                  label: "HP",
                  order: 1,
                  backgroundColor: "#80C141",
                  borderColor: "#80C141",
                  // pointBackgroundColor: "#80C141",
                  // pointBorderColor: "#80C141",
                  data: this.data.hpCount,
                  tension: 0.4,
                  fill: true,
               },
               {
                  label: "FL",
                  order: 2,
                  backgroundColor: "#5b57a5",
                  borderColor: "#5b57a5",
                  // pointBackgroundColor: "#5b57a5",
                  // pointBorderColor: "#5b57a5",
                  data: this.data.flCount,
                  tension: 0.4,
                  fill: true,
               },
               {
                  label: "TL",
                  order: 3,
                  backgroundColor: "#F47306",
                  borderColor: "#F47306",
                  // pointBackgroundColor: "#F47306",
                  // pointBorderColor: "#F47306",
                  data: this.data.tlCount,
                  tension: 0.4,
                  fill: true,
               },
               {
                  label: "PL",
                  order: 4,
                  backgroundColor: "#406020",
                  borderColor: "#406020",
                  // pointBackgroundColor: "#406020",
                  // pointBorderColor: "#406020",
                  data: this.data.plCount,
                  tension: 0.4,
                  fill: true,
               },
            ],
         },
         options: this.options,
      });
   },
};
</script>

<style lang="scss" scoped></style>
